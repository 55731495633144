<template>
  <!-- 编辑采购单 -->
  <div class="container">
    <div class="topBtns">
      <el-button class="btnColor btns" @click="confirmSave">保存</el-button>
    </div>
    <!-- 基本信息 -->
    <div class="itemBox">
      <h4 class="boxTitle">基本信息</h4>
      <div class="contentBox">
        <div class="inputItem">
          <div class="inputLabel">购买订单号:</div>
          <el-input
            class="inputs"
            placeholder="请输入内容"
            v-model="baseInfoVo.buyOrderNumber"
          >
          </el-input>
        </div>
        <div class="inputItem">
          <div class="inputLabel">购买来源:</div>
          <el-select
            class="inputs"
            v-model="baseInfoVo.platformType"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in platformTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputItem">
          <div class="inputLabel">代购单号:</div>
          <el-select
            class="inputs"
            v-model="baseInfoVo.dgOrderId"
            clearable
            placeholder="请选择"
            filterable
            remote
            reserve-keyword
            :remote-method="getDaiGouList"
            value-key="dgOrderNumber"
          >
            <el-option
              v-for="(item, index) in IndentList"
              :key="index"
              :label="item.dgOrderNumber"
              :value="item.dgOrderId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputItem">
          <div class="inputLabel">会员号:</div>
          <el-select
            class="inputs"
            @change="huiayu"
            v-model="baseInfoVo.memberId"
            placeholder="请选择会员"
            clearable
            filterable
            remote
            disabled
            reserve-keyword
            :remote-method="getMembers"
            value-key="memberId"
          >
            <el-option
              v-for="(item, index) in memberList"
              :label="item.memberId + ' (' + item.nickname + ')'"
              :value="item.memberId"
              :key="index"
            >
            </el-option>
          </el-select>
          <!-- <el-input
            class="inputs"
            placeholder="请输入内容"
            v-model="baseInfoVo.memberId"
          >
          </el-input> -->
        </div>
        <div class="inputItem">
          <div class="inputLabel">归属代理商:</div>
          <el-input
            class="inputs"
            placeholder="自动获取"
            v-model="baseInfoVo.agentName"
            disabled
          >
          </el-input>
        </div>
        <template v-if="pageType == 'edit'">
          <div class="inputItem">
            <div class="inputLabel">购买订单号:</div>
            <el-input
              class="inputs"
              placeholder="自动获取"
              v-model="baseInfoVo.buyOrderNumber"
            >
            </el-input>
          </div>
          <div class="inputItem">
            <div class="inputLabel">创建时间:</div>
            <el-input
              class="inputs"
              placeholder="自动获取"
              v-model="baseInfoVo.createTime"
              disabled
            >
            </el-input>
          </div>
          <div class="inputItem">
            <div class="inputLabel">创建人:</div>
            <el-input
              class="inputs"
              placeholder="自动获取"
              v-model="baseInfoVo.createUserName"
              disabled
            >
            </el-input>
          </div>
        </template>

        <div class="inputItem textareaItem">
          <div class="inputLabel">备注:</div>
          <el-input
            class="inputs"
            placeholder="请输入"
            clearable
            v-model="baseInfoVo.comment"
          >
          </el-input>
        </div>
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="itemBox">
      <h4 class="boxTitle">商品信息</h4>
      <div class="contentBox_goods">
        <div class="btnList marginBottom10">
          <!-- <el-button class="btnColor btns">选择添加商品</el-button> -->
          <el-button class="btnColor btns" @click="addGoods(1)"
            >手动添加商品</el-button
          >
        </div>
        <div
          class="goodsInfo"
          v-for="(item, index) in goodsInfoData"
          :key="index"
        >
          <!-- 商品表格 -->
          <el-table
            :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column prop="itemTitle" label="商品标题" min-width="250">
              <template slot-scope="scope">
                <div class="goodsImgTitle">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="goodsInfoData[scope.$index].itemPic"
                    fit="contain"
                  ></el-image>
                  <div class="goodsTitle">
                    {{ goodsInfoData[scope.$index].itemTitle }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="itemUrl" label="商品链接" min-width="200">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].itemUrl }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="itemSpecs" label="规格" width="150">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].itemSpecs }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="actualPrice" label="单价" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].actualPrice }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="itemPrice" label="原单价" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].itemPrice }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="itemCount" label="数量" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].itemCount }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="domesticFreight" label="国内运费" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].domesticFreight }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="itemAmount" label="总价" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].itemAmount }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="isChangePrices" label="是否改价" width="80">
              <template slot-scope="scope">
                <div
                  style="color: #f00"
                  v-if="goodsInfoData[scope.$index].isChangePrices"
                >
                  是
                </div>
                <div v-if="!goodsInfoData[scope.$index].isChangePrices">否</div>
              </template>
            </el-table-column>
            <el-table-column prop="reasons" label="修改原因" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].reasons }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="platformTypeShow"
              label="商品来源"
              width="80"
            >
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].platformTypeShow }}</div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="80">
              <template slot-scope="scope">
                <el-button
                  @click="addGoods(0, scope.row)"
                  type="text"
                  size="small"
                  >修改</el-button
                >
                <el-button @click="DelGoods(scope.row)" type="text" size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="contentBox">
            <!-- 商品信息 -->

            <!-- <div class="inputItem">
              <div class="inputLabel">是否改价格:</div>
              <el-select
                class="inputs"
                v-model="isChangePrices"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in ischangePrice"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="inputItem">
              <div class="inputLabel">修改单价:</div>
              <el-input
                clearable
                :disabled="isChangePrices == 'no'"
                class="inputs"
                placeholder="请输入内容"
                v-model="item.actualPrice"
              >
              </el-input>
            </div>

            <div class="inputItem">
              <div class="inputLabel">修改原因:</div>
              <el-input
                clearable
                class="inputs"
                :disabled="isChangePrices == 'no'"
                placeholder="请输入内容"
                v-model="item.reasons"
              >
              </el-input>
            </div>
            <div class="inputItem">
              <div class="inputLabel">商品来源:</div>
              <el-select
                class="inputs"
                v-model="item.platformType"
                filterable
                clearable
                placeholder="请输入关键词"
              >
                <el-option
                  v-for="item in platformTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
            <!-- <div class="inputItem">
              <div class="inputLabel">商品sku:</div>
              <el-input
              clearable
                class="inputs"
                placeholder="请输入内容"
                v-model="item.reasons"
              >
              </el-input>
            </div> -->

            <div class="inputItem" style="width: 100%">
              <div class="inputLabel">下单备注:</div>
              <el-input
                clearable
                class="inputs"
                placeholder="请输入内容"
                v-model="item.orderRemarks"
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="pageType == 'edit'">
      <!-- 付款信息 -->
      <div class="itemBox" v-if="financeInVo">
        <h4 class="boxTitle">付款信息</h4>
        <div class="contentBox">
          <div class="inputItem">
            <div class="inputLabel">付款状态:</div>
            <el-input
              class="inputs"
              disabled
              v-model="financeInVo.payStatusShow"
            ></el-input>
            <!-- <el-select
              class="inputs"
              v-model="financeInVo.payStatus"
              filterable
              clearable
              disabled
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in fkztList"
                :key="index"
                :label="item.payStatusShow"
                :value="item.payStatus"
              >
              </el-option>
            </el-select> -->
          </div>
          <div class="inputItem">
            <div class="inputLabel">资金账号:</div>
            <el-input
              class="inputs"
              disabled
              v-model="financeInVo.financeAssetaccountName"
            ></el-input>
            <!-- <el-select
              class="inputs"
              v-model="financeInVo.bbb"
              filterable
              clearable
              disabled
              placeholder="请选择"
            >
              <el-option
                v-for="item in zjzhList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
          </div>
          <div class="inputItem">
            <div class="inputLabel">核销时间:</div>
            <el-input
              class="inputs"
              disabled
              v-model="financeInVo.payTime"
            ></el-input>
          </div>
          <!-- <div class="inputItem">
            <div class="inputLabel longLabel">核销操作时间:</div>
            <el-input class="inputs" disabled v-model="financeInVo.payTime"></el-input>
          </div> -->
          <div class="inputItem">
            <div class="inputLabel">核销操作人:</div>
            <el-input class="inputs" disabled v-model="financeInVo.payUserName">
            </el-input>
          </div>
          <div class="imageItem">
            <div class="inputLabel">核销图片:</div>
            <div class="ImageList">
              <el-image
                v-for="(item, index) in financeInVo.fileVos"
                :key="index"
                class="hxImgs"
                :src="item.fileUrl"
              ></el-image>
            </div>
          </div>
        </div>
      </div>
      <!-- 包裹信息 -->
      <div class="itemBox" v-if="packageInfoVos.length > 0">
        <h4 class="boxTitle">包裹信息</h4>
        <div
          class="contentBox"
          style="margin-bottom: 20px"
          v-for="(item, index) in packageInfoVos"
          :key="index"
        >
          <div v-if="item.operationType != 'DEL'">
            <div class="topBtns" style="width: 100%; margin-bottom: 10px">
              <el-button class="btnColor btns" @click="delPack(index)"
                >删除第{{ index + 1 }}个包裹</el-button
              >
            </div>
            <div class="inputItem">
              <div class="inputLabel">快递单号:</div>
              <el-input
                class="inputs"
                placeholder="请输入内容"
                v-model="item.courierNumber"
              >
              </el-input>
            </div>
            <div class="inputItem">
              <div class="inputLabel">快递公司:</div>
              <el-select
                class="inputs"
                v-model="item.expressName"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in expressArr"
                  :key="item.expressNumber"
                  :label="item.expressName"
                  :value="item.expressNumber + ''"
                >
                </el-option>
              </el-select>
            </div>
            <div class="inputItem">
              <div class="inputLabel">货物品类:</div>
              <el-select
                class="inputs"
                v-model="item.itemCategoryCode"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in huowuleibieList"
                  :key="index"
                  :label="item.showName"
                  :value="item.itemCategoryCode + ''"
                >
                </el-option>
              </el-select>
            </div>
            <div class="inputItem">
              <div class="inputLabel">仓库:</div>
              <el-select
                class="inputs"
                v-model="item.storageId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in cangkuList"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id + ''"
                >
                </el-option>
              </el-select>
            </div>
            <div class="inputItem textareaItem">
              <div class="inputLabel">备注:</div>
              <el-input
                class="inputs"
                placeholder="请输入"
                clearable
                v-model="item.packageComment"
              >
              </el-input>
            </div>
            <div class="inputItem checkboxItem">
              <el-checkbox v-model="item.isPrecious">是否贵重物品</el-checkbox>
              <el-checkbox v-model="item.loanStatus"
                >是否垫付快递费</el-checkbox
              >
            </div>
            <div class="pmBOx">
              <el-table border :data="item.packageItems" style="width: 100%">
                <el-table-column
                  prop="itemName"
                  show-overflow-tooltip
                  label="品名"
                >
                  <template slot-scope="scope">
                    <el-input
                      placeholder="请输入"
                      v-model="scope.row.itemName"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="unitPrice" label="单价"
                  ><template slot-scope="scope">
                    <el-input
                      placeholder="请输入"
                      v-model="scope.row.unitPrice"
                    ></el-input> </template
                ></el-table-column>
                <el-table-column prop="quantity" label="数量"
                  ><template slot-scope="scope">
                    <el-input
                      placeholder="请输入"
                      v-model="scope.row.quantity"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <i
                      v-if="scope.$index == 0"
                      @click="addBtns(scope.row, index)"
                      style="font-size: 24px"
                      class="el-icon-circle-plus"
                    ></i>
                    <i
                      v-else
                      @click="jianBtns(scope.row, scope.$index, index)"
                      style="font-size: 24px"
                      class="el-icon-remove"
                    ></i>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- 弹出窗 -->
    <div>
      <el-dialog
        title="手动添加商品"
        :visible.sync="dialogVisible"
        width="30%"
        @close="handleClose"
      >
        <div class="addGoodsForm">
          <el-form
            :inline="true"
            :model="addGoodsData"
            label-width="80px"
            class="demo-form-inline"
            :rules="rulesA"
            ref="addForm"
          >
            <el-form-item label="商品标题" prop="itemTitle" style="width: 100%">
              <el-input
                v-model="addGoodsData.itemTitle"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品链接" prop="itemUrl">
              <el-input
                v-model="addGoodsData.itemUrl"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="规格" prop="itemSpecs">
              <el-input
                v-model="addGoodsData.itemSpecs"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="单价" prop="itemPrice">
              <div class="disFlex">
                <el-input
                  type="number"
                  v-model="addGoodsData.itemPrice"
                  disabled
                  placeholder="请输入"
                  @input="inputNumfn($event, 'itemPrice')"
                ></el-input>
                <el-button
                  v-if="!addGoodsData.isChangePrices"
                  type="warning"
                  @click="isChangePriceFN()"
                  >修改价格</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
              v-if="addGoodsData.isChangePrices"
              label="当前单价"
              prop="actualPrice"
            >
              <div class="disFlex">
                <el-input
                  type="number"
                  v-model="addGoodsData.actualPrice"
                  @input="inputNumfn($event, 'actualPrice')"
                  placeholder="请输入"
                ></el-input>
                <el-button
                  v-if="addGoodsData.isChangePrices"
                  type="warning"
                  @click="isChangePriceFN()"
                  >取消修改</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
              v-if="addGoodsData.isChangePrices"
              label="修改原因"
              prop="reasons"
            >
              <div class="disFlex">
                <el-input
                  v-model="addGoodsData.reasons"
                  placeholder="请输入"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="数量" prop="itemCount">
              <el-input
                type="number"
                v-model="addGoodsData.itemCount"
                @input="inputNumfn($event, 'itemCount')"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="国内运费" prop="domesticFreight">
              <el-input
                type="number"
                v-model="addGoodsData.domesticFreight"
                @input="inputNumfn($event, 'domesticFreight')"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="总价">
              <el-input
                type="number"
                readonly
                v-model="addGoodsData.itemAmount"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品来源">
              <el-input
                readonly
                v-model="addGoodsData.platformTypeShow"
                placeholder="请输入"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="comfirmBtns">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <div style="height: 50px; width: 100%"></div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tool from "@/assets/js/tool";
export default {
  components: {},
  data() {
    return {
      // 基础信息
      orderForm: "", //订单类型
      agentId: "", //归属代理商id
      dialogVisible: false,
      //其他定义
      options: [],
      loading: false,
      checkList: ["选中且禁用", "复选框 A"],
      tableData: [{ name: "xxx" }],
      memberList: [],
      //基本信息
      baseInfoVo: {
        buyOrderNumber: "", //购买订单
        comment: "", //备注
        dgOrderId: "", //代购订单id
        memberId: "", //会员号
        platformType: "淘宝", //购买来源
        buyOrderNumber: "",
        agentName: "",
      },
      financeInVo: {},
      //包裹信息
      packageInfoVos: [
        {
          courierNumber: "",
          expressName: "",
          isPrecious: false,
          loanStatus: false,
          packageComment: "",
          operationType: "UPDATE", //操作类型: ADD 为 新增; UPDATE 为 修改; DEL 为 删除;
          packageItems: [
            {
              itemNameCode: "",
              operationType: "UPDATE", //操作类型: ADD 为 新增; UPDATE 为 修改; DEL 为 删除;
              packageItemId: "",
              itemName: "",
              quantity: "",
              unitPrice: "",
            },
          ],
        },
      ],
      fkztList: [], //付款状态
      zjzhList: [], //资金账户
      expressArr: [], //快递公司列表
      cangkuList: [], //仓库列表
      huowuleibieList: [], //品类列表
      // 商品信息
      goodsInfoData: [],
      addGoodsData: {
        actualPrice: "",
        domesticFreight: "",
        itemAmount: "",
        itemCount: "",
        itemPic: "",
        itemPrice: "",
        itemSpecs: "",
        itemTitle: "",
        itemUrl: "",
        orderRemarks: "",
        platformType: "taobao:platform_type",
        reasons: "",
        shopName: "",
        skuId: "",
        isChangePrices: false,
        reasons: "",
        platformTypeShow: "淘宝",
      },
      isChangePrices: "no",
      ischangePrice: [
        {
          value: "yes",
          label: "是",
        },
        {
          value: "no",
          label: "否",
        },
      ],
      platformTypeList: [
        {
          value: "taobao:platform_type",
          label: "淘宝",
        },
        {
          value: "jing_dong:platform_type",
          label: "京东",
        },
        {
          value: "pin_duo_duo:platform_type",
          label: "拼多多",
        },
        {
          value: "wei_pin_hui:platform_type",
          label: "唯品会",
        },
      ],
      IndentList: [], //代购单列表
      pageType: "",
      delLists: [], //已删除的品名
      rulesA: {
        itemTitle: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        itemUrl: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        itemSpecs: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        itemPrice: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        itemCount: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        domesticFreight: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
      },
    };
  },
  created() {
    this.getVipList();
    this.getIndentList();

    this.getExpressList(); //快递公司列表
    this.myGetStorageList(); //获取仓库下拉列表
    this.getItemCategoryListNoPage(); //获取货物类别下拉
  },
  mounted() {},
  activated() {
    let querys = this.$route.query;
    console.log(querys);
    if (querys.pageType) {
      this.pageType = querys.pageType;
    }

    if (querys.pageType && querys.pageType == "edit") {
      //修改进来,
      this.reloadData();
      // 获取采购单详情
      Api.bmg_getPurchaseOrderDetail({
        purchaseOrderId: querys.purchaseOrderId,
      }).then((res) => {
        let datas = res.data.result || {};
        console.log(datas);
        //获取当前待购单id
        this.getIndentList(datas.baseInfoVo.dgOrderNumber);
        this.baseInfoVo = datas.baseInfoVo || {}; //基本信息
        this.financeInVo = datas.financeInVo || {}; //付款信息

        datas.itemInfoVos.forEach((item) => {
          item.operationType = "UPDATE";
        });
        datas.packageInfoVos.forEach((item) => {
          item.operationType = "UPDATE";
          item.packageItems.forEach((item2) => {
            item2.operationType = "UPDATE";
          });
        });
        this.goodsInfoData = datas.itemInfoVos || {}; //商品信息
        this.packageInfoVos = datas.packageInfoVos || {}; //包裹信息

        if (this.goodsInfoData[0].actualPrice) {
          this.goodsInfoData[0].isChangePrices = true;
        }
      });
    }
    if (querys.pageType && querys.pageType == "caigou" && querys.dgOrderId) {
      //代购单采购进来进来,
      this.reloadData();
      // 获取代购单详情
      Api.bmg_getDgOrderDetail({ dgOrderId: querys.dgOrderId }).then((res) => {
        console.log(res.data.result);
        let datas = res.data.result || {};
        if (datas.orderItem) {
          this.goodsInfoData = datas.orderItem;
        }
        if (datas.baseInfoVo) {
          this.baseInfoVo = datas.baseInfoVo;
        }
      });
    }
  },
  deactivated() {},
  watch: {
    addGoodsData: {
      handler(a, b) {
        let price = 0;

        if (a.isChangePrices) {
          price = a.actualPrice || 0;
        } else {
          price = a.itemPrice || 0;
        }

        this.addGoodsData.itemAmount = this.Tools.mathToFixed(
          price * a.itemCount + a.domesticFreight * 1
        );
      },
      deep: true,
    },
  },
  methods: {
    linkPages(url) {
      window.open(url);
    },
    // 是否修改价格
    isChangePriceFN() {
      this.addGoodsData.isChangePrices = !this.addGoodsData.isChangePrices;
      console.log(this.addGoodsData.isChangePrices);
      if (this.addGoodsData.isChangePrices) {
      } else {
        this.addGoodsData.actualPrice = this.addGoodsData.itemPrice;
      }
    },

    //只能输入正数和小数
    inputNumfn(e, type) {
      let num = Number(e.replace(/[^\d.]/g, ""));
      if (type == "actualPrice") {
        this.addGoodsData.actualPrice = num;
      }
      if (type == "itemPrice") {
        this.addGoodsData.itemPrice = num;
      }
      if (type == "itemCount") {
        num = Number(e.replace(/\D+/, "")) || "";
        this.addGoodsData.itemCount = num;
      }
      if (type == "domesticFreight") {
        this.addGoodsData.domesticFreight = num;
      }
    },
    //快递公司列表
    getExpressList() {
      //  快递公司
      Api.expressChooseList().then((res) => {
        if (res.data.status === "success") {
          this.expressArr = res.data.result;
        } else {
          console.log(`${res.data.message}--快递公司`);
        }
      });
    },
    myGetStorageList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        this.cangkuList = res.data.result || [];
      });
    },
    //获取货物类别下拉
    getItemCategoryListNoPage() {
      Api.getItemCategoryListNoPage().then((res) => {
        this.huowuleibieList = res.data.result || [];
      });
    },
    addBtns(row, idx) {
      console.log(row);
      this.packageInfoVos[idx].packageItems.push({
        itemNameCode: "",
        operationType: "UPDATE", //操作类型: ADD 为 新增; UPDATE 为 修改; DEL 为 删除;
        packageItemId: "",
        itemName: "",
        quantity: "",
        unitPrice: "",
      });
    },
    jianBtns(row, index, idx) {
      let delLists = [];
      let delData = this.packageInfoVos[idx].packageItems[index];
      delData.operationType = "DEL";
      let obj = {
        idx: idx,
        data: delData,
      };
      delLists.push(obj);
      this.delLists = delLists;
      this.packageInfoVos[idx].packageItems.splice(index, 1);
    },
    delPack(idx) {
      this.$confirm("是否删除该包裹?", "提示")
        .then(() => {
          this.packageInfoVos[idx].operationType = "DEL";
          console.log(this.packageInfoVos);
        })
        .catch(() => {});
    },
    // 获取代购单列表
    getIndentList(e) {
      let formData = {
        agentId: "", //归属代理商id
        courierNumber: "", //快递单号
        dgOrderNumber: e || "", //代购订单号
        startCreateTime: "", //起始创建时间
        endCreateTime: "", //结束创建时间
        startPayTime: "", //起始付款时间
        endPayTime: "", //结束付款时间
        isSensitive: "", //是否敏感 1=敏感 0=非敏感
        memberId: "", //会员号
        memberName: "", //会员名称
        orderForm: "", //订单类型
        orderType: "", //代购状态
        unpackingService: "", //拆箱服务
        pageStart: 1, //页码
        pageTotal: 50, //每页条数
      };

      Api.bmg_getDgOrderList(formData).then((res) => {
        if (res.data.status == "success") {
          let IndentList = res.data.result.data || [];
          let nerArr = [];
          let newListArr = [];
          IndentList.forEach((item) => {
            if (nerArr.indexOf(item.dgOrderId) == -1) {
              nerArr.push(item.dgOrderId);
              item.dgOrderId = item.dgOrderId + "";
              newListArr.push(item);
            }
          });
          this.IndentList = newListArr;
        }
      });
    },
    // 重置
    reloadData() {
      this.baseInfoVo = {
        buyOrderNumber: "", //购买订单
        comment: "", //备注
        dgOrderId: "", //代购订单id
        memberId: "", //会员号
        platformType: "淘宝", //购买来源
        buyOrderNumber: "",
        agentName: "",
      };
      // 商品信息
      this.goodsInfoData = [];
      this.addGoodsData = {
        actualPrice: "",
        domesticFreight: "",
        itemAmount: "",
        itemCount: "",
        itemPic: "",
        itemPrice: "",
        itemSpecs: "",
        itemTitle: "",
        itemUrl: "",
        orderRemarks: "",
        platformType: "taobao:platform_type",
        reasons: "",
        shopName: "",
        skuId: "",
        operationType: "UPDATE",
        isChangePrices: false,
        reasons: "",
        platformTypeShow: "淘宝",
      };
      this.isChangePrices = "no";
    },
    // 选择会员
    huiayu(e) {
      this.memberList.forEach((item) => {
        let arr = [];
        let names = [];
        if (e == item.memberId) {
        }
      });
    },
    // 远程搜索代购单
    getDaiGouList(e) {
      if (e.length > 0) {
        this.getIndentList(e);
      }
    },
    // 远程搜索会员
    getMembers(e) {
      this.getVipList(e);
    },
    getVipList(e) {
      Api.bmg_getMemberDownList({
        memberId: e || "",
      }).then((res) => {
        if (res.data.status === "success") {
          this.memberList = res.data.result.data || [];
          // this.total = res.data.result.pageCount || 0;
        }
      });
    },
    // 关闭弹窗执行事件
    handleClose() {},
    // 删除商品
    DelGoods(e) {
      this.$confirm("是否删除该商品?", "提示")
        .then((res) => {
          this.goodsInfoData[0].operationType = "DEL";
        })
        .catch(() => {});
    },
    // 手动添加商品
    addGoods(e) {
      console.log(e);
      let num = 0;
      let FormData = this.goodsInfoData[0];
      this.goodsInfoData.forEach((item) => {
        if (item.operationType != "DEL") {
          num++;
          FormData = item;
        }
      });

      if (e == 1 && num > 0) {
        this.addGoodsData.operationType = "ADD ";
        this.$message.warning("目前只能添加一条数据");
        return false;
      } else if (e == 0) {
        // 修改
        this.addGoodsData.operationType = "UPDATE";
        this.addGoodsData.actualPrice = FormData.actualPrice || "";
        this.addGoodsData.domesticFreight = FormData.domesticFreight || "";
        this.addGoodsData.itemAmount = FormData.itemAmount || "";
        this.addGoodsData.itemCount = FormData.itemCount || "";
        this.addGoodsData.itemId = FormData.itemId || "";
        this.addGoodsData.itemPic = FormData.itemPic || "";
        this.addGoodsData.itemPrice = FormData.itemPrice || "";
        this.addGoodsData.itemSpecs = FormData.itemSpecs || "";
        this.addGoodsData.itemTitle = FormData.itemTitle || "";
        this.addGoodsData.itemUrl = FormData.itemUrl || "";
        this.addGoodsData.orderRemarks = FormData.orderRemarks || "";
        this.addGoodsData.platformType = FormData.platformType || "";
        this.addGoodsData.reasons = FormData.reasons || "";
        this.addGoodsData.shopName = FormData.shopName || "";
        this.addGoodsData.skuId = FormData.skuId || "";
        this.addGoodsData.platformTypeShow = FormData.platformTypeShow || "";
        this.addGoodsData.isChangePrices = FormData.isChangePrices || false;
      }
      this.dialogVisible = true;
    },
    // 确认添加
    comfirmBtns() {
      if (!this.addGoodsData.itemTitle) {
        this.$message.warning("商品标题不能为空");
        return false;
      }
      if (!this.addGoodsData.itemSpecs) {
        this.$message.warning("商品规格不能为空");
        return false;
      }
      if (!this.addGoodsData.itemPrice) {
        this.$message.warning("商品单价不能为空");
        return false;
      }
      if (!this.addGoodsData.itemCount) {
        this.$message.warning("商品数量不能为空");
        return false;
      }
      if (!this.addGoodsData.itemAmount) {
        this.$message.warning("商品总价不能为空");
        return false;
      }
      this.goodsInfoData = [];

      this.goodsInfoData.push(this.addGoodsData);
      this.dialogVisible = false;
    },

    // 确定下单
    confirmSave() {
      if (this.goodsInfoData.length == 0) {
        this.$message.warning("请添加一个商品");
        return false;
      }
      if (this.goodsInfoData[0].actualPrice == "") {
        this.goodsInfoData[0].actualPrice =
          this.goodsInfoData[0].itemPrice || 0;
      }
      this.packageInfoVos.forEach((item) => {
        item.isPrecious = item.isPrecious ? "1" : "0"; //是否贵重物品(0=否 1=是)
        item.loanStatus = item.loanStatus
          ? "dian_fu:loan_status"
          : "bu_dian_fu:loan_status"; //bu_dian_fu:loan_status=不垫付快递费;dian_fu:loan_status=垫付快递费;
      });
      for (var i = 0; i < this.delLists.length; i++) {
        this.packageInfoVos[this.delLists[i].idx].packageItems.push(
          this.delLists[i].data
        );
      }

      let param = {
        buyOrderNumber: this.baseInfoVo.buyOrderNumber,
        comment: this.baseInfoVo.comment || "",
        dgOrderId: this.baseInfoVo.dgOrderId,
        memberId: this.baseInfoVo.memberId,
        platformType: this.baseInfoVo.platformType,
        itemReqs: this.goodsInfoData,
        packageReqs: this.packageInfoVos,
        purchaseOrderId: this.baseInfoVo.purchaseOrderId,
      };
      param.sign = tool.getSign(param);
      Api.bmg_updatePurchaseOrder(param)
        .then((res) => {
          // 返回采购单列表
          this.$message.success(res.data.message || "修改成功");
          this.reloadData();
          this.Tools.closePage();
          this.reload();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
$borColor: #797979;
// .topBtns {
//     position: fixed;
//     top: 80px;
//     left: 220px;
// }
.btns {
  background-color: #f0a261;
  color: #fff;
  border: none;
  padding: 10px;
}
.goodsInfo {
  border: 1px solid #d7d7d7;
  padding: 10px;
}

.itemBox {
  margin-top: 20px;
  width: 100%;

  .boxTitle {
    margin: 0;
    margin-bottom: 4px;
  }

  .contentBox {
    padding: 10px;
    // border: 1px solid $borColor;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;

    .inputItem {
      //   width: 30%;
      width: 270px;
      min-width: 200px;
      margin-right: 20px;
      margin-bottom: 10px;
      height: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
      border: 1px solid $borColor;
      border-radius: 4px;

      .inputLabel {
        width: 74px;
        padding-left: 6px;
        font-size: 14px;
      }

      .longLabel {
        width: 100px;
        min-width: 100px;
      }
      .inputs {
        flex: 1;
        border: none;

        ::v-deep {
          .el-input__inner {
            border: none;
            height: 28px;
            line-height: 28px;
            min-height: 28px;
          }
        }
      }
    }
    .imageItem {
      border: none;
      display: flex;
      .ImageList {
        display: flex;
        flex-wrap: wrap;

        .hxImgs {
          width: 100px;
          height: 100px;
          margin-left: 10px;
        }
      }
    }

    .textareaItem {
      width: 562px;
    }
    .checkboxItem {
      min-width: 562px;
      border: none;
    }
  }
  .contentBox_goods {
    padding: 10px;
    padding-bottom: 0;

    .goodsImgTitle {
      display: flex;
      align-items: center;
      padding: 4px 0;

      .goodsTitle {
        flex: 1;
      }
    }
    ::v-deep {
      .el-table .cell {
        white-space: normal;
      }
    }
  }
}

.packServe {
  //   flex: 1;
  width: 100%;
  display: flex;
  margin-right: 20px;
  margin-bottom: 10px;

  .inputLabel {
    width: 74px;
    min-width: 74px;
    padding-left: 6px;
    font-size: 14px;
  }
}

.marginBottom10 {
  margin-bottom: 10px;
}

.addGoodsForm {
  /deep/ .el-form--inline .el-form-item {
    display: flex;
  }

  /deep/ .el-form--inline .el-form-item__content {
    flex: 1;
  }
}

.pmBOx {
  width: 600px;
  // margin: 0 auto;
  /deep/ .el-input__inner {
    border: none;
  }
}

.disFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
